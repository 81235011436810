import React from "react"
import { FaArrowRight } from "react-icons/fa"

import SEO from "../components/seo"

import { Link } from "gatsby"

import "../scss/contactus.scss"
import "../scss/contactform.scss"

import AboutUsImg from "../assets/img/youData/contactus/about-us.svg"
import EmailIcon from "../assets/img/youData/contactus/email.png"
import CallIcon from "../assets/img/youData/contactus/phone.png"
import SupportIcon from "../assets/img/youData/contactus/tech.png"
import appendScript from '../utils/appendScript';

const ContactUsPage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=25", "contact-us-mautic-form");

  return (
    <div className="pt-4 my-5 contactUs pt-md-0">
      <SEO
        title="Contact Us"
        keywords={[`youpal`, `youpal group`, "youData", "youData contact"]}
      />
      <section className="container contactUsPageSection">
        <h2>Have a Question?</h2>
        <p className="contactUsSubtitle">
          Send us a message
        </p>
        <div className="formDiv">
          {/* Mautic form */}
          <div id='contact-us-mautic-form'/>
        </div>
      </section>

      <section className="container contactsSection">
        <div className="row">
          <div className="col iconP">
            <img src={EmailIcon} />
            <h3>Email us</h3>
            <p>
              If you have any additional or urgent queries reach us on the
              following email:
            </p>
            <p className="plink">
              <a href="mailto:hello@youdata.se">hello@youdata.se</a>
            </p>
          </div>
          <div className="col iconP">
            <img src={CallIcon} />
            <h3>Call us</h3>
            <p>
              If you really want to hear our voice, please reach out to the
              following number:
            </p>
            <p className="plink">
              <a href="tel:+4681-241-1707">+46 8-124 117 07</a>
            </p>
          </div>
          <div className="col iconP">
            <img src={SupportIcon} />
            <h3>Support</h3>
            <p>
              You can also get lucky via contacting one of our amazing team
              members via live chat
            </p>
            <p className="plink">
              <a href="mailto:hello@youdata.se">
                <span style={{ marginRight: "20px" }}>Go chatting</span>
                <FaArrowRight style={{ fill: "#ffa370" }} />
              </a>
            </p>
          </div>
        </div>
      </section>

      <section className="container my-2 text-left my-md-5">
        <div className="row">
          <h2 className="mx-auto mb-4 contactUsFooterTitle mt-xl-5 mt-md-3 d-block d-md-none">
            About Us
          </h2>
          <div className="col-xl-4 col-md-5 col-12 offset-md-1 offset-xl-1">
            <img
              src={AboutUsImg}
              alt="About us"
              className="contactUsFooterImg"
            />
          </div>
          <div className="col-xl-4 col-md-5 col-12 offset-md-1 offset-xl-1">
            <h2 className="contactUsFooterTitle mt-xl-5 mt-md-3 d-none d-md-block">
              About Data
            </h2>
            <p className="mb-5 contactUsFooterText">
              Data is not only used in science and technology, but it also has a
              huge influence on our everyday life. It gives us the ability to
              collect and analyze everything we do and leaves behind a digital
              footprint. The capability to handle huge and ever-expanding data
              is fully transforming our understanding of the world around us.
              The integration of data in our everyday life is essential. Even
              now, data influences how we work, exercise and shop and, this is
              just the beginning.
            </p>
          </div>
          <Link to="/about-us" className="mx-auto">
            <button
              type="click"
              className="mt-0 text-white contactUsButton btn font-weight-bold d-block d-md-none"
            >
              READ MORE
            </button>
          </Link>
        </div>
      </section>
    </div>
  )
}

export default ContactUsPage
